
import { Vue, Options } from 'vue-class-component';
import { UserService } from '@/services/api';
import { IModalAction } from '@/lib';
import { BaseSwitch, BaseButton, ActionModal, UnitsNumberInput } from '@/lib/components';
import { verifyInactivityTimeoutMinutes } from '@/helpers/user.helper';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';
import { useProgressStore } from '@/stores/progress.store';

@Options({
  components: {
    ActionModal,
    BaseSwitch,
    BaseButton,
    UnitsNumberInput
  }
})
export default class AccountSettingPage extends Vue {
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();
  progressStore = useProgressStore();
  saving = false;
  loading = true;
  requires_mfa = true;

  inactivity_timeout_minutes = 30;
  errors: { [key: string]: Array<string> } = {};
  showMFAModal = false;
  userService = new UserService();

  get mfaModalActions(): IModalAction[] {
    return [
      {
        label: this.$t('platform.profile.confirm-reset') as string,
        color: 'danger',
        onClick: () => this.resetMFA()
      },
      {
        label: this.$t('platform.common.cancel') as string,
        color: 'ghost',
        onClick: () => (this.showMFAModal = false)
      }
    ];
  }

  get user() {
    return this.sessionStore.currentUser;
  }

  get inactivityTimeoutString(): string {
    return this.inactivity_timeout_minutes.toString();
  }

  set inactivityTimeoutString(value: string) {
    this.inactivity_timeout_minutes = Number(value);
  }

  async beforeCreate() {
    this.progressStore.startProgress();
    await this.sessionStore.fetchCurrentUser();
    this.progressStore.finishProgress();
    this.loading = false;
    this.requires_mfa = this.sessionStore.currentUser?.requires_mfa;
    this.inactivity_timeout_minutes = this.sessionStore.currentUser?.inactivity_timeout_seconds / 60;
  }

  updateInactivityTimeoutMinutes() {
    if (!verifyInactivityTimeoutMinutes(Number(this.inactivity_timeout_minutes))) {
      this.inactivity_timeout_minutes = 30;
    }
  }

  async resetMFA() {
    this.saving = true;
    try {
      await this.userService.resetMFA();
      this.showMFAModal = false;
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.profile.reset-mfa-success')
      });
    } catch (error) {
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.error'),
        label: this.$t('platform.profile.reset-mfa-error')
      });
    } finally {
      this.saving = false;
    }
  }

  async requestPasswordReset() {
    this.saving = true;
    try {
      await this.userService.requestResetPasswordEmail({ email: this.user.email });
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.profile.reset-password-success')
      });
    } catch (err) {
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.profile.reset-password-error')
      });
    } finally {
      this.saving = false;
    }
  }

  async save() {
    this.saving = true;
    this.errors = {};

    try {
      const user = (
        await this.userService.updateAccountSetting({
          requires_mfa: this.requires_mfa,
          inactivity_timeout_seconds: this.inactivity_timeout_minutes * 60
        })
      ).data;
      this.sessionStore.updateCurrentUser(user);
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.profile.update-success')
      });
    } catch (error) {
      this.errors = error.response?.data?.errors || {};
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.profile.update-error')
      });
    } finally {
      this.saving = false;
    }
  }

  cancel() {
    this.requires_mfa = this.sessionStore.currentUser.requires_mfa;
    this.inactivity_timeout_minutes = this.sessionStore.currentUser.inactivity_timeout_seconds / 60;
  }
}
